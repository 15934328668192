import axios from "axios";
import { useEffect, useState } from "react";

const Test = () => {
  const [Elements, setElements] = useState([]);
  const [data_characters, setData_character] = useState([]);

  const apiEndpoint_Characters = process.env.REACT_APP_API_ENDPIONT_CHARACTERS;
  const authString = process.env.REACT_APP_API_KEY;
  const headers = {
    Authorization: `Basic ${btoa(authString)}`,
  };
  useEffect(() => {
    axios
      .get(apiEndpoint_Characters, { headers: headers })
      .then((response) => {
        setData_character(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPIONT}elements`, {
        headers: headers,
      })
      .then((response) => {
        setElements(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //https://drive.google.com/file/d/1kdpLlJa_0_qEaXIAEwKKkD5R3Q4NWqTw/view?usp=drive_link
  const test =
    "https://drive.google.com/thumbnail?id=1kdpLlJa_0_qEaXIAEwKKkD5R3Q4NWqTw";

  //   const test =
  //     "https://api.ambr.top/assets/UI/UI_AvatarIcon_Emilie.png?vh=2024071502";

  const getElementUrl = (charElement) => {
    const foundElement = Elements.find((ele) => ele.name === charElement);
    return foundElement ? foundElement.url : null;
  };

  return (
    <>
      <div className="text-center">
        {/* <img src={element()} className="w-[133px] h-[133px]" /> */}
        <img src={test} className="w-[133px] h-[133px] ml-[45%] " alt="" />
        {data_characters
          //   .sort((a, b) => b.id - a.id)
          .sort((a, b) => a.id - b.id)
          .filter((char) => char.image_url)
          .map((char) => {
            const elementUrls = getElementUrl(char.elements);
            return (
              <span key={char.id} className="text-center text-white">
                <span
                  {...char}
                  key={char.id}
                  className="my-5 border-orange-700 border-[5px] inline-block mx-2"
                >
                  <img
                    alt=""
                    src={char.image_url}
                    className="w-[133px] h-[133px]"
                    loading="lazy"
                  />
                  <img
                    alt=""
                    src={elementUrls}
                    className="z-[2] float-left absolute w-[32px] h-[32px]"
                    loading="lazy"
                  />
                  <h1>{char.name}</h1>
                  <h1>{char.elements}</h1>
                  <h1>{char.weapon}</h1>
                  <h1>{char.rarity}</h1>
                </span>
              </span>
            );
          })}
      </div>
    </>
  );
};

export default Test;
