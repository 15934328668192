import { useState, useEffect } from "react";
import { DateTime } from "luxon";

const Countdown = ({ targetDate }) => {
  // Define the target DateTime in Thailand's time zone
  const targetDateTime = DateTime.fromISO(targetDate, { zone: "Asia/Bangkok" });

  // Function to calculate the remaining time
  const calculateTimeLeft = () => {
    const now = DateTime.local().setZone("Asia/Bangkok");
    const difference = targetDateTime
      .diff(now, ["days", "hours", "minutes", "seconds"])
      .toObject();

    // If the target date is in the past, return an empty object
    if (difference.seconds < 0) {
      return {};
    }

    return {
      days: Math.floor(difference.days),
      hours: Math.floor(difference.hours),
      minutes: Math.floor(difference.minutes),
      seconds: Math.floor(difference.seconds),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();
      setTimeLeft(updatedTimeLeft);

      if (Object.keys(updatedTimeLeft).length === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDateTime]);

  // Function to add leading zeros
  const addLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <div className="">
      {timeLeft.days !== undefined ? (
        <>
          <div className="text-center">
            <span className="px-1">{addLeadingZeros(timeLeft.days)} Day</span>
            <span className="px-1">
              {addLeadingZeros(timeLeft.hours)} Hours
            </span>
            <span className="px-1">
              {addLeadingZeros(timeLeft.minutes)} Minutes
            </span>
            <span className="px-1">
              {addLeadingZeros(timeLeft.seconds)} Seconds
            </span>
          </div>
        </>
      ) : (
        <div className="">🎉 The countdown has ended! 🎉</div>
      )}
    </div>
  );
};

const CNY = () => {
  const targetDate_NewYear = "2024-12-31T23:59:59";
  const targetDate_Christmas = "2024-12-24T23:59:59";

  return (
    <>
      <div className="flex justify-center items-center h-[75vh]">
        <div className="select-none">
          <div className="text-[5vw] bg-gradient-to-r from-lime-400 via-yellow-300 to-rose-600 bg-clip-text text-transparent">
            <h1 className="text-center">Merry Christmas And Happy New Year</h1>
            <div className="flex justify-center gap-20 mt-3">
              <div className="text-[2.5vh]">
                <h1 className="text-center">Christmas Countdown</h1>
                <Countdown targetDate={targetDate_Christmas} />
              </div>
              <div className="text-[2.5vh]">
                <h1 className="text-center">New Year Countdown</h1>
                <Countdown targetDate={targetDate_NewYear} />
              </div>
            </div>
          </div>
          <div className="text-pink-600 text-center text-[3.8vw] select-none mt-20">
            Welcome to My Website!!
          </div>
          <p className="text-center text-[.8vw] select-none text-white ">
            Thank You for Visiting
          </p>
        </div>
      </div>
    </>
  );
};

export default CNY;
