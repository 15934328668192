import { useState, useEffect } from "react";
import { Store } from "react-notifications-component";

const LoginApi = (props) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    if (user === process.env.REACT_APP_LOGIN_USER) {
      if (password === process.env.REACT_APP_LOGIN_PASSWORD) {
        const data = { user: user, password: password, login: true };
        props.updateUserData(data);
        Store.addNotification({
          title: "Login Successfully!!",
          message: `Login Successfully.`,
          type: "success",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
      } else {
        Store.addNotification({
          title: "Password incorrect!!",
          message: `password not correct.`,
          type: "warning",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__tada"],
          animationOut: ["animate__animated animate__fadeOutTopLeft"],
        });
      }
    } else {
      Store.addNotification({
        title: "Username incorrect!!",
        message: `username not correct.`,
        type: "warning",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 7000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__tada"],
        animationOut: ["animate__animated animate__fadeOutTopLeft"],
      });
    }
  };

  return (
    <>
      <div className="mt-[20vh] text-center ">
        <h1 className="text-white  text-5xl">Wellcome to Api Adjust</h1>
        <div className="flex justify-center text-white mt-5">
          <h1>User</h1>
          <input
            type="text"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            className="ml-3 text-black w-60  focus:ring-0 focus:border-none focus:outline-none"
          />
        </div>
        <div className="flex justify-center text-white mt-5">
          <h1>Password</h1>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="ml-3 text-black w-60 focus:ring-0 focus:border-none focus:outline-none"
          />
        </div>
        <button
          onClick={login}
          className="text-white mt-5 bg-slate-700 py-2 rounded-xl px-7"
        >
          Login
        </button>
      </div>
    </>
  );
};

export default LoginApi;
