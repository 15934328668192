// // make hover better and dont change other
// import { Link } from "react-router-dom";

// const Project = () => {
//   return (
//     <div className="relative inline-block text-left group">
//       <button
//         className="inline-flex justify-center w-full px-10 py-2 text-[2vw]
//         text-white hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
//       >
//         Project
//       </button>
//       <div className="absolute left-0 w-56 mt-2 origin-top-left bg-slate-600 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-1000 ease-in-out">
//         <div className="py-1">
//           <Link
//             to="/project/genshinrandomizer"
//             className="group flex items-center px-4 py-2 text-[.925vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Genshin Randomizer v2
//           </Link>
//           <Link
//             to="https://kiyang-101.web.app/genshin/randomizer"
//             className="group flex items-center px-4 py-2 text-[.925vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Genshin Randomizer v1
//           </Link>
//           <Link
//             to="https://real-time-chat-01.web.app/"
//             className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Real Time Chat
//           </Link>
//           <Link
//             to="https://todo-list-kiyang101.vercel.app/"
//             className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             To-Do List
//           </Link>
//           <Link
//             to="https://kiyang-101.web.app/valorant/RandomAgents"
//             className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Valorant Randomizer
//           </Link>
//           <Link
//             to="https://kiyang-101.web.app/rov/random"
//             className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Rov Randomizer
//           </Link>
//           <Link
//             to="https://kiyang-101.web.app/time/thailand"
//             className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
//           >
//             Time Thailand
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// const NavBar = () => {
//   return (
//     <>
//       <div className="bg-slate-500 h-[9vh] sticky top-0 flex items-center z-20">
//         <div className="flex items-center w-full px-5 text-white text-[2vw]">
//           <div className="flex-none">
//             <Link to="/" className="">
//               Kiyang.net
//             </Link>
//           </div>
//           <div className="flex-1 flex justify-center items-center gap-5">
//             <Link
//               to="/profile"
//               className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
//             >
//               Profile
//             </Link>
//             <Project />
//             <a
//               href="https://github.com/Kiyang101"
//               className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
//             >
//               Github
//             </a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default NavBar;

import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const Project = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInteractable, setIsInteractable] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    // Clear any existing timers
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
    setIsInteractable(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    // Delay setting pointer-events to 'none' by 500ms
    timeoutRef.current = setTimeout(() => {
      setIsInteractable(false);
    }, 500); // Delay duration in milliseconds
  };

  useEffect(() => {
    return () => {
      // Cleanup the timer on unmount
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="inline-flex justify-center w-full px-10 py-2 text-[2vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 hover:scale-105 transform rounded-xl ease-in-out duration-700">
        Project
      </button>
      <div
        className={`absolute left-0 w-56 mt-2 origin-top-left bg-slate-600 rounded-md shadow-lg transition-all duration-[.75s] ease-in-out transform ${
          isOpen ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-5"
        } ${isInteractable ? "" : "pointer-events-none"}`}
      >
        <div className="py-1">
          <Link
            to="/project/genshinrandomizer"
            className="flex items-center px-4 py-2 text-[.925vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Genshin Randomizer v2
          </Link>
          <Link
            to="https://kiyang-101.web.app/genshin/randomizer"
            className="flex items-center px-4 py-2 text-[.925vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Genshin Randomizer v1
          </Link>
          <Link
            to="https://real-time-chat-01.web.app/"
            className="flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Real Time Chat
          </Link>
          <Link
            to="https://todo-list-kiyang101.vercel.app/"
            className="flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            To-Do List
          </Link>
          <Link
            to="https://kiyang-101.web.app/valorant/RandomAgents"
            className="flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Valorant Randomizer
          </Link>
          <Link
            to="https://kiyang-101.web.app/rov/random"
            className="flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Rov Randomizer
          </Link>
          <Link
            to="https://kiyang-101.web.app/time/thailand"
            className="flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 w-full hover:scale-105 text-left duration-500 ease-in-out"
          >
            Time Thailand
          </Link>
        </div>
      </div>
    </div>
  );
};

const NavBar = () => {
  return (
    <>
      <div className="bg-slate-500 h-[9vh] sticky top-0 flex items-center z-20">
        <div className="flex items-center w-full px-5 text-white text-[2vw]">
          <div className="flex-none">
            <Link to="/" className="">
              Kiyang.net
            </Link>
          </div>
          <div className="flex-1 flex justify-center items-center gap-5">
            <Link
              to="/profile"
              className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 hover:scale-105 transform rounded-xl ease-in-out duration-700"
            >
              Profile
            </Link>
            <Project />
            <a
              href="https://github.com/Kiyang101"
              className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] hover:text-slate-200 hover:scale-105 transform rounded-xl ease-in-out duration-700"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
